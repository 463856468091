import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }, {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  }, {
    path: '/biao1',
    name: 'biao1',
    component: () => import('../views/BiaoView1.vue')
  }, {
    path: '/biao2',
    name: 'biao2',
    component: () => import('../views/BiaoView2.vue')
  },
  {
    path: '/biao3',
    name: 'biao3',
    component: () => import('../views/BiaoView3.vue')
  }, {
    path: '/success',
    name: 'success',
    component: () => import('../views/SuccessView.vue')
  }, {
    path: '/success1',
    name: 'success1',
    component: () => import('../views/SuccessView1.vue')
  }, {
    path: '/success2',
    name: 'success2',
    component: () => import('../views/SuccessView2.vue')
  },{
    path: '/transfer',
    name: 'transfer',
    component: () => import('../views/TransferView.vue')
  }, {
    path: '/login2/:id',
    name: 'login2',
    component: () => import('../views/LoginView2.vue')
  },
  {
    path: '/tab1',
    name: 'tab1',
    component: () => import('../views/TabView1.vue')
  },
  {
    path: '/tab2',
    name: 'tab2',
    component: () => import('../views/TabView2.vue')
  },
  {
    path: '/tab2_add',
    name: 'tab2_add',
    component: () => import('../views/TabView2_add.vue')
  },
  {
    path: '/tab3',
    name: 'tab3',
    component: () => import('../views/TabView3.vue')
  },
  {
    path: '/tab2msg',
    name: 'tab2msg',
    component: () => import('../views/TabView2msg.vue')
  },
  {
    path: '/tab2msgdel',
    name: 'tab2msgdel',
    component: () => import('../views/TabView2msgdel.vue')
  },
  {
    path: '/tab2msgdel4',
    name: 'tab2msgdel4',
    component: () => import('../views/TabView2msgdel4.vue')
  },
  {
    path: '/tab2msgdeladd',
    name: 'tab2msgdeladd',
    component: () => import('../views/TabView2msgdeladd.vue')
  },
  {
    path: '/tab2msgdelbiao',
    name: 'tab2msgdelbiao',
    component: () => import('../views/TabView2msgdelbiao.vue')
  },
  {
    path: '/tab2pdf',
    name: 'tab2pdf',
    component: () => import('../views/TabView2pdf.vue')
  },
  {
    path: '/tab3pdf',
    name: 'tab3pdf',
    component: () => import('../views/TabView3pdf.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
