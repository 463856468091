<template>
  <div id="appbox">
    <!-- v-if="$route.meta.requiresHeader"  -->
    <!-- <HeaderView />  -->
    <router-view />
    <!--  <FooterView />  -->
  </div>
</template>
<script>
// import HeaderView from "./components/HeaderView.vue";
// import FooterView from "./components/FooterView.vue";
export default {
  name: "App",
  components: {
    // HeaderView,
    // FooterView,
  },
  data() {
    return {
      title: "",
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/tab/public.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,
ol,
dl {
  list-style: none;
}
​#appbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
  color: #000;
}
.wapper {
  width: 1200px;
  margin: 0 auto;
}
@font-face {
  font-family: "EBGaramond_Medium";
  src: url("../src/assets/font/EBGaramond/EBGaramond-Medium.ttf");
}
@font-face {
  font-family: "EBGaramond_Regular";
  src: url("../src/assets/font/EBGaramond/EBGaramond-Regular.ttf");
}
@font-face {
  font-family: "Nunito_Medium";
  src: url("../src/assets/font/Nunitot/Nunito-Medium.ttf");
}
@font-face {
  font-family: "Nunito_Regular";
  src: url("../src/assets/font/Nunitot/Nunito-Regular.ttf");
}
.opc0 {
  opacity: 0;
}
textarea {
  resize: none;
}
.el-input__inner{
  border: 1px solid hsla(213,5%,56%,.56) !important;
}
.el-select-dropdown__item{
  font-family: "Nunito_Medium"  !important;

}
input:focus-within {
  border: 2px solid #7aabdd !important; /* 获取焦点时添加边框 */
}
input:focus {
  border: 2px solid #7aabdd !important; /* 获取焦点时添加边框 */
}
input:focus-visible {
  outline: none !important;
  border: 2px solid #7aabdd !important; /* 获取焦点时添加边框 */
}
textarea:focus-visible {
  outline: none !important;
  border: 2px solid #7aabdd !important; /* 获取焦点时添加边框 */
}
</style>
