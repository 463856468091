<template>
  <div class="home">
    <div class="header">
      <img class="logo" src="@/assets/logo.png" alt="" />
      <div class="header_right">
        <ul>
          <li>Home</li>
          <li @mouseover="logintkShow = false" @mousemove="logintkShow = true">
            Log In
          </li>
        </ul>
        <div class="loginbtn"  @click="tkShow = true">Request Demo</div>
        <div
          class="logintk"
          v-if="logintkShow"
          @mouseleave="logintkShow = false"
        >
          <div
            class="tk_item"
            :class="{ active: activeItem === 'founders' }"
            @mouseover="activeItem = 'founders'"
            @mouseout="activeItem = ''"
            @click="openNewTab(1)"
          >
            <!-- @click="$router.push({ name: 'login2', params: { id: 1 } })" -->
            Startup Founder
          </div>
          <div
            class="tk_item"
            :class="{ active: activeItem === 'investor' }"
            @mouseover="activeItem = 'investor'"
            @mouseout="activeItem = ''"
            @click="openNewTab(2)"
          >
            <!-- @click="$router.push({ name: 'login2', params: { id: 2 } })" -->
            Venture Capitalist
          </div>
          <!-- <div
            class="tk_item"
            :class="{ active: activeItem === 'startup' }"
            @mouseover="activeItem = 'startup'"
            @mouseout="activeItem = ''"
          >
            Startup
          </div> -->
        </div>
      </div>
    </div>
    <div class="content">
      <div class="wenben1">
        AI Virtual Interviews & Automated <br />
        Assessments in VC Deal Sourcing
      </div>
      <div class="wenben5">Bring Investment Science and Founder Potential Intelligence to VC</div>
      <div class="btn1" @click="tkShow = true">Book a Demo</div>
      <!-- <img class="tu1" src="@/assets/zatu1.png" alt="" /> -->
      <img
        class="tu1"
        v-show="quIndex == 1"
        src="@/assets/gif/u_1_final.gif"
        alt=""
      />
      <img
        class="tu1"
        v-show="quIndex == 2"
        src="@/assets/gif/u_2_final.gif"
        alt=""
      />
      <img
        class="tu1"
        v-show="quIndex == 3"
        src="@/assets/gif/u_3_final.gif"
        alt=""
      />
      <img
        class="tu1"
        v-show="quIndex == 4"
        src="@/assets/gif/u_4_final.gif"
        alt=""
      />
      <img
        class="tu1"
        v-show="quIndex == 5"
        src="@/assets/gif/u_5_final.gif"
        alt=""
      />
      <img
        class="tu1"
        v-if="quIndex == 6"
        src="@/assets/gif/u_6_final.gif"
        alt=""
      />
      <div class="xuhao_list">
        <div class="xuhao_item" @click="quIndex = 1">
          <div class="qq" :class="{ active: quIndex == 1 }">1</div>
          <div class="wenben2">
            VC Funding <br />
            Common Application
          </div>
        </div>
        <div class="xuhao_item" @click="quIndex = 2">
          <div class="qq" :class="{ active: quIndex == 2 }">2</div>
          <div class="wenben2">
            VC Tailored Automated <br />
            Assessments
          </div>
        </div>
        <div class="xuhao_item" @click="quIndex = 3">
          <div class="qq" :class="{ active: quIndex == 3 }">3</div>
          <div class="wenben2">
            Founder-Centric <br />
            AI Virtual Interviews
          </div>
        </div>
        <div class="xuhao_item" @click="quIndex = 4">
          <div class="qq" :class="{ active: quIndex == 4 }">4</div>
          <div class="wenben2">
            Comprehensive AI <br />
            Due Diligence Report
          </div>
        </div>
        <div class="xuhao_item" @click="quIndex = 5">
          <div class="qq" :class="{ active: quIndex == 5 }">5</div>
          <div class="wenben2">Comprehensive CRM</div>
        </div>
        <div class="xuhao_item" @click="quIndex = 6">
          <div class="qq" :class="{ active: quIndex == 6 }">6</div>
          <div class="wenben2">
            Secure Investment<br />
            Funds Transfer
          </div>
        </div>
      </div>
      <div class="xian1"></div>
      <div class="wenben2">Key Features</div>
      <div class="wenben3">
        The end-to-end, one-stop solution <br />
        designed for VC by VC
      </div>
      <div class="tuwenbox">
        <img class="tutu" src="@/assets/zatu3.png" alt="" />
        <div class="wen">
          <div class="wen_title1">
            Founder-Centric <br />
            AI Virtual Interviews & Analytics
          </div>
          <div class="wen_title2">
            Deal sourcing enhancements with data-driven analytics, powered by
            science
          </div>
          <div class="wen_list">
            <img src="@/assets/dui.png" alt="" />On demand AI virtual interviews
            foster investment team collaboration and better founder pitching
            experience
          </div>
          <div class="wen_list">
            <img src="@/assets/dui.png" alt="" /> Expand your deal flow with
            faster speed and high quality
          </div>
          <div class="wen_list">
            <img src="@/assets/dui.png" alt="" /> Leverage AI-powered tools to
            optimize your due diligence process
          </div>
        </div>
      </div>
      <div class="tuwenbox">
        <div class="wen">
          <div class="wen_title1">
            Automated Assessments <br />
            Backed by Science
          </div>
          <div class="wen_title2">
            Unlock new insights with founder potential intelligence software
          </div>
          <div class="wen_list">
            <img src="@/assets/dui.png" alt="" />Game-based assessments
            effectively evaluate relevant entrepreneurial traits and founder’s
            potential with consistency, minimizing biases with AI
          </div>
          <div class="wen_list">
            <img src="@/assets/dui.png" alt="" />Vertically-trained AI LLM to
            assess product market potential and validate technical feasibility
            with ease
          </div>
          <div class="wen_list">
            <img src="@/assets/dui.png" alt="" />Compare results with past
            successful startup founders to unlock new insights in venture
            investing
          </div>
        </div>
        <img class="tutu" src="@/assets/zatu2.png" alt="" />
      </div>
      <div class="tuwenbox">
        <img class="tutu" src="@/assets/zatu4.png" alt="" />
        <div class="wen">
          <div class="wen_title1">AI Search Engine for VCs, Startups, LPs</div>
          <div class="wen_title2">
            AI-powered search engine using VC-specific LLM to accurately match
            startups, VCs, co-investors, and LPs
          </div>
          <div class="wen_list">
            <img src="@/assets/dui.png" alt="" />Leverage unstructured startup,
            VC, and LP data to rank results by relevance and adapt to the
            dynamic VC market
          </div>
          <div class="wen_list">
            <img src="@/assets/dui.png" alt="" />Reveal hidden connections
            between LPs, VCs, and founders using social network graphs
          </div>
          <div class="wen_list">
            <img src="@/assets/dui.png" alt="" />Utilize structured data and
            content-based collaborative filtering to find the best matches
          </div>
        </div>
      </div>
      <div class="tuwenbox">
        <div class="wen">
          <div class="wen_title1">Comprehensive CRM Solution</div>
          <div class="wen_title2">
            Comprehensive system enabling diverse investor-founder engagements
          </div>
          <div class="wen_list">
            <img src="@/assets/dui.png" alt="" />Direct message, schedule video
            meeting, send calendar invites, all in the same platform
          </div>
          <div class="wen_list">
            <img src="@/assets/dui.png" alt="" />Digitally sign AI-generated
            legal documents with a single click
          </div>
          <div class="wen_list">
            <img src="@/assets/dui.png" alt="" /> Securely wire investment funds with a seamless experience
          </div>
        </div>
        <img class="tutu" src="@/assets/zatu5.png" alt="" />
      </div>
      <div class="wenbenbox1">
        <div class="wenben4">
          Discover the Future of <br />
          Venture Capital Technology
        </div>
        <div class="btn2" @click="tkShow = true">See PitchVue in Action</div>
      </div>
      <div class="footer">
        <div class="footer_top">
          <img class="logo" src="@/assets/logo.png" alt="" />
          <div class="footer_top_right">
            <div class="menu_list">
              <div class="title">Product</div>
              <div class="menu_item">Home</div>
              <div class="menu_item">Login</div>
              <div class="menu_item" @click="tkShow = true">Book a Demo</div>
            </div>
            <div class="menu_list">
              <div class="title">Company</div>
              <div class="menu_item">Jobs</div>
              <div class="menu_item">justin.wang@pitchvue.ai</div>
            </div>
            <div class="menu_list">
              <div class="title">Legal</div>
              <div class="menu_item">Privacy Policy</div>
              <div class="menu_item">Terms of Service</div>
            </div>
          </div>
        </div>
        <div class="footer_bottom">
          <div class="footer_bottom_left">© 2024 by Pitchvue</div>
          <div class="footer_bottom_right">
            <img src="@/assets/icon1.png" alt="" />
            <img src="@/assets/icon2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="tk_box" v-if="tkShow">
      <div class="tk_content">
        <img
          class="close"
          @click="tkShow = false"
          src="@/assets/close.png"
          alt=""
        />
        <div class="tk_title">Get in Touch with Us</div>
        <div class="tktk_box">
          <div class="tk_item">
            <div class="tk_item_title">Name <span>*</span></div>
            <input type="text" v-model="name" />
          </div>
          <div class="tk_item">
            <div class="tk_item_title">Email <span>*</span></div>
            <input type="text" v-model="email" />
          </div>
        </div>
        <div class="tk_item">
          <div class="tk_item_title">Leave us a message...</div>
          <textarea name="" id="" cols="30" rows="10" v-model="message"></textarea>
        </div>
        <div class="tk_btn" @click="submitForm">Submit</div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      // Existing data
      logintkShow: false,
      tkShow: false,
      activeItem: "",
      quIndex: 1,

      // New data properties for email form
      name: '',
      email: '',
      message: '',
    };
  },
  methods: {
    openNewTab(i) {
      const routeUrl = this.$router.resolve({
        name: "login2",
        params: { id: i },
      });
      window.open(routeUrl.href, "_blank");
    },
    submitForm() {
      const serviceId = 'service_52p0qqi';
      const templateId = 'template_y1adbej';
      const userId = 'wrhY9fbYgS2HYIq6W';

      const templateParams = {
        from_name: this.name,
        reply_to: this.email,
        message: this.message,
      };

      emailjs.send(serviceId, templateId, templateParams, userId)
        .then(() => {
          alert('Demo request sent successfully!');
          this.name = '';
          this.email = '';
          this.message = '';
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          alert('An error occurred. Please try again later.');
        });
    },
  },
};
</script>


<style lang="scss" scoped>
body {
  background-color: #f6f6f6;
}
.home {
  background-image: url("@/assets/indexbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.header {
  width: 100%;
  padding: 22px 210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f8fe;
  position: fixed;
  top: 0;
  left: 0;
  .logo {
    height: 63px;
  }
  .header_right {
    display: flex;
    align-items: center;
    position: relative;
    ul {
      display: flex;
      align-items: center;
      li {
        font-family: "EBGaramond_Medium";
        font-weight: bold;
        font-size: 20px;
        color: #0d2457;
        line-height: 23px;
        margin-right: 50px;
        cursor: pointer;
      }
    }
    .loginbtn {
      background-color: #699fd6;
      color: #fff;
      width: 162px;
      height: 64px;
      border-radius: 39px;
      font-size: 20px;
      line-height: 64px;
      text-align: center;
      font-family: "EBGaramond_Medium";
      font-weight: bold;
      cursor: pointer;
    }
    .loginbtn:hover {
      background: #233d8e;
      color: #fff;
    }
    .logintk {
      position: absolute;
      right: 164px;
      top: 75px;
      width: 140px;
      // height: 116px;
      background: #ffffff;
      box-shadow: 0px 6px 12px 0px rgba(188, 188, 188, 0.2);
      border-radius: 4px 4px 4px 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding:10px 0;
      .tk_item {
        width: 100%;
        height: 40px;
        border-radius: 44px;
        text-align: center;
        font-family: "EBGaramond_Medium";
        font-weight: bold;
        font-size: 16px;
        line-height: 40px;
        color: #0d2457;
        cursor: pointer;
      }
      // .tk_item:hover {}
      .active {
        // background: rgba(105, 159, 214, 0.19);
        // color: #699fd6;
        color: #699fd6;
      }
    }
  }
}
.content {
  width: 100%;
  // min-height: 5700px;
  min-height: 5600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .wenben1 {
    width: 1004px;
    height: 136px;
    font-family: "EBGaramond_Regular";
    font-weight: 600;
    font-size: 64px;
    color: #0d2457;
    line-height: 68px;
    text-align: center;
    margin: 330px auto 0;
  }
  .wenben5{ font-family: "EBGaramond_Regular";
    font-weight: 600;
    font-size: 26px;
    color: #233D8E;
    line-height: 68px;
    text-align: center;
    margin:56px auto 150px;

  }
  .btn1 {
    width: 240px;
    height: 64px;
    background: #ebf7ff;
    box-shadow: 0px 8px 24px 0px rgba(27, 84, 143, 0.22);
    border-radius: 58px 58px 58px 58px;
    line-height: 64px;
    font-family: "Nunito_Medium";
    font-weight: bold;
    font-size: 20px;
    color: #0d2457;
    text-align: center;
    cursor: pointer;
    margin-bottom: 105px;
  }
  .btn1:hover {
    background: #233d8e;
    color: #fff;
  }
  .tu1 {
    width: 1400px;
    margin-bottom: 40px;
    border-radius: 24px;
  }
  .xuhao_list {
    width: 1400px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    .xuhao_item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      .qq {
        width: 60px;
        height: 60px;
        background: #fafafa;
        border-radius: 30px;
        border: 4px solid #233d8e;
        font-family: "Nunito_Medium";
        font-weight: bold;
        font-size: 20px;
        color: #0d2457;
        line-height: 60px;
        text-align: center;
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .active {
        background: #7aabdd;
        color: #fff;
      }
      .wenben2 {
        font-family: "Nunito_Medium";
        font-weight: bold;
        font-size: 20px;
        color: #5e5e5e;
        line-height: 20px;
        text-align: center;
      }
    }
  }
  .xian1 {
    color: #7aabdd;
    width: 1400px;
    height: 2px;
    background: #7aabdd;
    margin-bottom: 88px;
  }
  .wenben2 {
    font-family: "EBGaramond_Regular";
    font-weight: 600;
    font-size: 26px;
    color: #0d2457;
    line-height: 30px;
    text-align: center;
    margin-bottom: 48px;
  }
  .wenben3 {
    font-family: "EBGaramond_Regular";
    font-weight: 600;
    font-size: 50px;
    color: #0d2457;
    line-height: 59px;
    text-align: center;
    margin-bottom: 150px;
  }
  .tuwenbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 160px;
    .tutu {
      width: 700px;
    }
    .wen {
      width: 650px;
      .wen_title1 {
        font-family: "EBGaramond_Regular";
        font-weight: 600;
        font-size: 42px;
        color: #0d2457;
        line-height: 40px;
        margin-bottom: 48px;
      }
      .wen_title2 {
        font-family: "EBGaramond_Regular";
        font-weight: normal;
        font-size: 18px;
        color: #5e5e5e;
        line-height: 21px;
        margin-bottom: 8px;
      }
      .wen_list {
        display: flex;
        margin: 16px 0;
        img {
          width: 33px;
          height: 33px;
          margin-right: 20px;
        }
        font-family: "Nunito_Regular";
        font-weight: bold;
        font-size: 20px;
        color: #5e5e5e;
        line-height: 30px;
      }
    }
  }
  .wenbenbox1 {
    width: 100%;
    background: #7aabdd;
    height: 374px;
    padding: 56px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .wenben4 {
      font-family: "EBGaramond_Regular";
      font-weight: 600;
      font-size: 50px;
      color: #ffffff;
      line-height: 56px;
      text-align: center;
    }
    .btn2 {
      cursor: pointer;
      width: 312px;
      height: 68px;
      background: #fdfdfd;
      border-radius: 58px 58px 58px 58px;
      font-family: "Nunito_Regular";
      font-weight: bold;
      font-size: 20px;
      color: #0d2457;
      line-height: 68px;
      text-align: center;
    }
    .btn2:hover {
      background: #233d8e;
      color: #fff;
    }
  }
  .footer {
    padding-top: 96px;
    .footer_top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;
      .logo {
        width: 240px;
        height: 80px;
        object-fit: cover;
      }
      .footer_top_right {
        display: flex;
        gap: 240px;
        .menu_list {
          .title {
            font-family: "EBGaramond_Regular";
            font-weight: 600;
            font-size: 24px;
            color: #0d2457;
            line-height: 40px;
          }
          .menu_item {
            font-family: "EBGaramond_Regular";
            font-weight: 600;
            font-size: 16px;
            color: #5e5e5e;
            line-height: 40px;
            cursor: pointer;
          }
        }
      }
    }
    .footer_bottom {
      border-top: #7aabdd 2px dashed;
      width: 1400px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      .footer_bottom_right {
        display: flex;
        align-items: center;
        gap: 30px;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
.tk_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 36, 87, 0.56);
  display: flex;
  justify-content: center;
  align-items: center;
  .tk_content {
    width: 885px;
    height: 598px;
    background: #ffffff;
    box-shadow: 0px 3px 19px 4px rgba(40, 75, 117, 0.13);
    border-radius: 16px 16px 16px 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .close {
      position: absolute;
      width: 26px;
      height: 26px;
      right: 27px;
      top: 24px;
    }
    .tk_title {
      font-family: "EBGaramond_Regular";
      font-weight: 600;
      font-size: 50px;
      color: #0d2457;
      line-height: 59px;
      margin: 37px 0 80px;
    }
    .tktk_box {
      display: flex;
      gap: 88px;
      margin-bottom: 42px;
    }
    .tk_item {
      .tk_item_title {
        font-family: "Nunito_Regular";
        font-weight: 500;
        font-size: 16px;
        color: #0d2457;
        line-height: 19px;
        span {
          color: #eb1111;
        }
      }
      input {
        width: 352px;
        height: 46px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 2px solid #aacaea;
        padding: 4px;
        box-sizing: border-box;
      }
      textarea {
        width: 795px;
        height: 115px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 2px solid #aacaea;
        padding: 8px;
        box-sizing: border-box;
      }
    }
    .tk_btn {
      margin-top: 48px;
      width: 236px;
      height: 68px;
      background: #7aabdd;
      border-radius: 58px 58px 58px 58px;

      font-family: "Nunito_Regular";
      font-weight: bold;
      font-size: 20px;
      color: #fafafa;
      line-height: 68px;
      text-align: center;
    }
  }
}
</style>
